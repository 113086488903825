import React, { useState } from "react";
import './Classes.css'

function Classes({ onScroll, currentSection }) {
    return (
            <div id='classes' className={`classes_Background ${currentSection === 'classes' ? 'active' : ''}`}>
                <div className="classes_Header classes_Underline">Join Me</div>
                <div className="classes_Content">
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA GRUPOWE</div>
                        <div className="classes_Information">CZWARTEK</div>
                        <div className="classes_Information">18:00</div>
                        <div className="classes_Information">P0</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA SOLO</div>
                        <div className="classes_Information">PIĄTEK</div>
                        <div className="classes_Information">19:00</div>
                        <div className="classes_Information">P2</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA SOLO</div>
                        <div className="classes_Information">PIĄTEK</div>
                        <div className="classes_Information">20:00</div>
                        <div className="classes_Information">P2</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA GRUPOWE</div>
                        <div className="classes_Information">NIEDZIELA</div>
                        <div className="classes_Information">16:00</div>
                        <div className="classes_Information">P0</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA SOLO</div>
                        <div className="classes_Information">NIEDZIELA</div>
                        <div className="classes_Information">17:00</div>
                        <div className="classes_Information">P1</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    {/* 
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA SOLO</div>
                        <div className="classes_Information">NIEDZIELA</div>
                        <div className="classes_Information">18:00</div>
                        <div className="classes_Information">P1</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile active" target="_blank" rel="noopener noreferrer">ZAPISZ SIĘ</a>
                    <div className="classes_Information_Container">
                        <div className="classes_Information classes_Type">ZAJĘCIA GRUPOWE</div>
                        <div className="classes_Information">NIEDZIELA</div>
                        <div className="classes_Information">19:00</div>
                        <div className="classes_Information">P0</div>
                        <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Desktop" target="_blank" rel="noopener noreferrer">GRUPA ZAMKNIETA</a>
                    </div>
                    <a href="https://www.dancefusion.com.pl/grafik/" className="classes_Status_Mobile" target="_blank" rel="noopener noreferrer">GRUPA ZAMKNIETA</a> */}
                </div>
                <div className="classes_End_Description">Nie znalazłeś grupy dla siebie?<span className="classes_End_Description_Highlight" onClick={() => {onScroll('contact')}}>Napisz do Mnie</span></div>
            </div>
    );
}

export default Classes;