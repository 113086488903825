import React, { useState } from "react";
import './Footer.css'

function Footer() {

    return (
        <div className="footer_Background">
            <div className="footer_Creator">Created By MarszalMeins</div>
            <a href="https://www.facebook.com/profile.php?id=61564396044080" className="footer_Facebook" target="_blank" rel="noopener noreferrer"></a>
        </div>
    );
}

export default Footer;