import React, { useState, useEffect } from "react";
import './Contact.css'

function Contact( {currentSection } ) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [statusMessage, setStatusMessage] = useState('Wyślij');
    const [status, setStatus] = useState(true)

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };

    const resetStatus = () => {
      // Timer, który ukryje wiadomość po 3 sekundach
      setTimeout(() => {
        setStatusMessage('Wyślij');
      }, 3000);

      setStatus(true)
    }

    const handleSubmit = async (e) => {
      if (status == false) {
        return
      }

      setStatus(false)

      e.preventDefault();
      setStatusMessage('Wysyłanie...');

      // Walidacja pola imię
      if (!name.trim()) {
        setStatusMessage('Imię nie może być puste.');
        return resetStatus();
      }

      // Walidacja pola e-mail
      if (!validateEmail(email)) {
        setStatusMessage('Podaj poprawny adres e-mail.');
        return resetStatus();
      }

      // Walidacja pola wiadomość
      if (!message.trim() || message.length < 2) {
        setStatusMessage('Wiadomość musi mieć co najmniej 2 znaki.');
        return resetStatus();
      }
      
      try {
        const response = await fetch('https://api.michalmarszalkowski-swing.com.pl/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name, email, message }),
        });
  
        if (response.ok) {
          setStatusMessage('Wiadomość wysłana pomyślnie!');
        } else {
          setStatusMessage('Wystąpił błąd podczas wysyłania wiadomości.');
        }
      } catch (error) {
        setStatusMessage('Error: ' + error.message);
      }

      setName('')
      setEmail('')
      setMessage('')
      resetStatus()
  };

    return (
            <div id='contact' className={`contact_Background ${currentSection === 'contact' ? 'active' : ''}`}>
                <div className="contact_Header contact_Underline">Contact Me</div>
                <div className="contact_Content_Container">
                    <div className="contact_Content">
                    Jeśli masz pytania dotyczące istniejących lub przyszłych grup zajęciowych, lekcji indywidualnych West Coast Swinga lub 
                    Tańca Użytkowego, napisz do mnie śmiało!. Zachęcam również do kontaktu, jeśli po prostu chcesz się przywitać.
                    </div>
                    <div className="contact_Input_Container">
                        <input placeholder="Imię" className="contact_Input" value={name} onChange={(e) => setName(e.target.value)} required/>
                        <input placeholder="Email" className="contact_Input" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        <textarea placeholder="Wiadomość" className="contact_Input contact_Textarea" value={message} onChange={(e) => setMessage(e.target.value)} required/>
                        <div className="contact_Send_Button" onClick={handleSubmit}>{statusMessage}</div>
                    </div>
                </div>
            </div>
    );
}

export default Contact;