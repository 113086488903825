import React, { useState } from "react";
import './About.css'

function About( {currentSection } ) {

    return (
            <div id='about' className={`about_Background ${currentSection === 'about' ? 'active' : ''}`}>
                <div className="about_Container">
                    <div className="about_Header about_Underline">About Me</div>
                    <div className="about_Content">
                        Michał urodził się 1 maja 1989 roku, obecnie jest znanym instruktorem tańca WCS w Trójmieście. Jego przygoda taneczna zaczęła się od  hip hopu i house, 
                        które trenował przez 3 lata, zanim w 2012 roku poznał swoją pasję do WCS.<br/><br/>
                        W domu nie podzielano jego miłości do tańca, co skutkowało brakiem wsparcia. Mimo tego znajdował siłę, czas i choćby skrawek podłogi, by móc trenować nowo 
                        poznane kroki, a w późniejszym okresie tworzyć swój własny styl. Dzięki swojej wytrwałości po 10 latach nauki i doskonalenie, został instruktorem WCS w 
                        szkole, w której stawiał swoje pierwsze kroki – Dance Fusion, to tutaj poczuł się jak w domu, odnalazł swoje małe miejsce na Ziemi.
                        Zawsze uśmiechnięty na zajęciach, przekazuje z humorem swoją wiedzę nowym grupom kursantów. Za każdym razem oddaje im całego siebie wkładając entuzjazm i 
                        serce w każdy trening.<br/><br/>
                        Michał jest inspiracją dla wielu osób. Jego taniec i wiedza o nim jest tym, czym szczerze chce się dzielić z innymi, by pomóc im znaleźć to, co on znalazł w 
                        Weście – Miłość, Pasję, Dom.
                    </div>
                </div>
            </div>
    );
}

export default About;