import React, { useState, useEffect, useRef } from "react";
import {FaBars} from 'react-icons/fa';
import "./WebNavbar.css"

function Navbar({ onScroll, currentSection }) {

    const navbarMenuRef = useRef()

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(true);

    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 499) {
            setScrolled(true);
            } else {
            setScrolled(false);
            }
        };
  
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    },  []);

    useEffect (() => {
        const handler = (e) => {
            if (!navbarMenuRef.current.contains(e.target)) {
                setIsMobileMenuOpen(true)
            }
        }
        document.addEventListener("mousedown", handler);

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    });

    useEffect(() => {
        if (currentSection != "") {
            setMobileActiveIndex(currentSection)
        }
        else {
            setMobileActiveIndex('main')
        }
    }, [currentSection]);

    const [mobileActiveIndex, setMobileActiveIndex] = useState('main');

    const handleMobileMenuOpen = () => setIsMobileMenuOpen(!isMobileMenuOpen)
    const handleMobileMenu = (id) => {
        setIsMobileMenuOpen(true);
        setMobileActiveIndex(id)
        onScroll(id)
    }

    return (
        <nav>
            <div className= {`navbar_Top_Menu ${scrolled ? 'navbar_Background' : ''}`}>
                <div className="navbar_Items">
                    <div className="navbar_Name">Michał Marszałkowski</div>
                    <FaBars className="navbar_Icon" ref={navbarMenuRef} onClick = {handleMobileMenuOpen}/>
                    <div className="navbar_Container">
                        <div className= {`navbar_Item ${mobileActiveIndex === 'main' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('main')}>HOME</div>
                        <div className= {`navbar_Item ${mobileActiveIndex === 'about' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('about')}>ABOUT</div>
                        <div className= {`navbar_Item ${mobileActiveIndex === 'classes' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('classes')}>CLASSES</div>
                        <div className= {`navbar_Item ${mobileActiveIndex === 'contact' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('contact')}>CONTACT</div>
                        <a href="https://www.facebook.com/profile.php?id=61564396044080" className="footer_Facebook" target="_blank" rel="noopener noreferrer"></a>
                    </div>
                </div>
            </div>
            <div ref={navbarMenuRef} className = {isMobileMenuOpen ? ("navbar_Side_Menu") : ("navbar_Side_Menu active")}>
                <div className="navbar_Side_Column_Items">
                    <div className= {`navbar_Item ${mobileActiveIndex === 'main' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('main')}>HOME</div>
                    <div className= {`navbar_Item ${mobileActiveIndex === 'about' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('about')}>ABOUT</div>
                    <div className= {`navbar_Item ${mobileActiveIndex === 'classes' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('classes')}>CLASSES</div>
                    <div className= {`navbar_Item ${mobileActiveIndex === 'contact' ? 'navbar_Underline' : ''}`} onClick={() => handleMobileMenu('contact')}>CONTACT</div>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;