import React, { useState, useEffect } from "react";
import './Main.css'

function Main( { currentSection } ) {

    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {
      const handleFadeByScroll = () => {
        setScrollY(window.scrollY);
      };
  
      window.addEventListener('scroll', handleFadeByScroll);
      return () => window.removeEventListener('scroll', handleFadeByScroll);
    }, []);

    return (
            <div id='main' className= {`main_Background ${currentSection === 'main' ? 'active' : ''}`}>
                <div className="main_Name ">Michał Marszałkowski</div>
                <div className="main_Motto" style={{ opacity: 1 - scrollY / 200 }}>It Started With <span className="main_Underline">The First Step</span></div>
                <div className="main_Black_Space_End"></div>
            </div>
    );
}

export default Main;