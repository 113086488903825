import React, { useState, useEffect } from "react";
import './App.css';
import { useMediaQuery } from 'react-responsive';

import Navbar from './components/WebNavbar';
import Main from './components/Main'
import About from './components/About';
import Classes from "./components/Classes";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

function App() {
  const [currentSection, setCurrentSection] = useState('main');

  const handleScrollByScroll = () => {
    const sections = [
      { id: 'main', ref: document.getElementById('main') },
      { id: 'about', ref: document.getElementById('about') },
      { id: 'classes', ref: document.getElementById('classes') },
      { id: 'contact', ref: document.getElementById('contact') }
    ];

    const scrollPosition = window.scrollY + window.innerHeight / 2;

    const visibleSection = sections.find(section => {
      if (section.ref) {
        const { offsetTop, offsetHeight } = section.ref;
        return scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight;
      }
      return false;
    });

    if (visibleSection) {
      setCurrentSection(visibleSection.id);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollByScroll);
    return () => window.removeEventListener('scroll', handleScrollByScroll);
  }, []);

  const handleScrollByClick = (id) => {
    if (isDesktop) {
      setCurrentSection(id)
    }
    else {
      if (id == 'about') {
        const section = document.getElementById(id);
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 300,
            behavior: 'smooth'
          });
        }
      }
      else {
        const section = document.getElementById(id);
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 85,
            behavior: 'smooth'
          });
        }
      }
    }
  };

  const isDesktop = useMediaQuery({ minWidth: 1024 });

  return (
    <div>
      {isDesktop ? (
        <div>
          <Navbar onScroll={handleScrollByClick} currentSection={currentSection}/>
          <div className="test_overlay">
            <Main currentSection={currentSection}/>
            <About currentSection={currentSection}/>
            <Classes onScroll={handleScrollByClick} currentSection={currentSection}/>
            <Contact currentSection={currentSection}/>
          </div>
        </div>
      ) : (
        <>
        <Navbar onScroll={handleScrollByClick} currentSection={currentSection}/>
        <Main />
        <About />
        <Classes onScroll={handleScrollByClick}/>
        <Contact />
        <Footer />
        </>
      )}
    </div>
  );
}

export default App;
